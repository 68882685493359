import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { MonitoringErrorHandler } from '@commons/services/error-handler/error-handler.service';
import { ApiHttpInterceptor } from '@commons/services/interceptor.service';
import { NewRelicBusService } from '@commons/services/new-relic-bus.service';

export function onInitializerBusService() {
	// eslint-disable-next-line no-console
	return () => {};
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Providers = [
	{ provide: ErrorHandler, useClass: MonitoringErrorHandler },
	{
		provide: HTTP_INTERCEPTORS,
		useClass: ApiHttpInterceptor,
		multi: true,
	},
	{
		provide: APP_INITIALIZER,
		useFactory: onInitializerBusService,
		multi: true,
		deps: [NewRelicBusService],
	},
];
