import { isPlatformBrowser } from '@angular/common';
import { ClassProvider, FactoryProvider, InjectionToken, PLATFORM_ID } from '@angular/core';
import { CustomWindow } from './custom-window.interface';

export const WINDOW = new InjectionToken('WindowToken');

export abstract class WindowRef {
	get nativeWindow(): CustomWindow | object {
		throw new Error('No implementado');
	}
}

export class BrowserWindowRef extends WindowRef {
	public hasNewRelicInstance: boolean;

	constructor() {
		super();
	}

	get nativeWindow(): CustomWindow | object {
		return window;
	}

	get HasNewRelicInstance(): boolean {
		return 'newrelic' in window;
	}
}

export function windowFactory(
	browserWindowRef: BrowserWindowRef,
	platformId: object
): CustomWindow | object {
	if (isPlatformBrowser(platformId)) {
		return browserWindowRef.nativeWindow;
	}

	return new Object();
}

const browserWindowProvider: ClassProvider = {
	provide: WindowRef,
	useClass: BrowserWindowRef,
};

export const windowProvider: FactoryProvider = {
	provide: WINDOW,
	useFactory: windowFactory,
	deps: [WindowRef, PLATFORM_ID],
};

export const WINDOW_PROVIDERS = [browserWindowProvider, windowProvider];
