import { IDataProccess } from '@commons/interfaces/data-proccess.interface';
import { ISignalR } from '@commons/interfaces/signalr.interface';

export class DataProccess {
	private businessProcess: string;
	private channel: string;
	private clientId: string;
	private company: string;
	private documentNumber: string;
	private documentType: string;
	private operationDate: string;
	private operationType: string;
	private transactionId: string;
	private signalR: ISignalR;
	private uri: string;
	private reniecId: string;
	private failedAttempsAuth: string;
	private failedAttempsEnroll: string;
	private identityFirstName: string;
	private identityLastName: string;

	public set Channel(x: string) {
		this.channel = x;
	}

	public get Channel(): string {
		return this.channel;
	}

	public set BusinessProcess(x: string) {
		this.businessProcess = x;
	}

	public get BusinessProcess(): string {
		return this.businessProcess;
	}

	public set ClientId(x: string) {
		this.clientId = x;
	}

	public get ClientId(): string {
		return this.clientId;
	}

	public set Company(x: string) {
		this.company = x;
	}

	public get Company(): string {
		return this.company;
	}

	public set DocumentNumber(x: string) {
		this.documentNumber = x;
	}

	public get DocumentNumber(): string {
		return this.documentNumber;
	}

	public set DocumentType(x: string) {
		this.documentType = x;
	}

	public get DocumentType(): string {
		return this.documentType;
	}

	public set OperationDate(x: string) {
		this.operationDate = x;
	}

	public get OperationDate(): string {
		return this.operationDate;
	}

	public set OperationType(x: string) {
		this.operationType = x;
	}

	public get OperationType(): string {
		return this.operationType;
	}

	get TransactionId(): string {
		return this.transactionId;
	}

	set TransactionId(x: string) {
		this.transactionId = x;
	}

	public set SignalR(x: ISignalR) {
		this.signalR = x;
	}

	public get SignalR(): ISignalR {
		return this.signalR;
	}

	public set Uri(x: string) {
		this.uri = x;
	}

	public get Uri(): string {
		return this.uri;
	}

	get ReniecId(): string {
		return this.reniecId;
	}

	set ReniecId(x: string) {
		this.reniecId = x;
	}

	get FailedAttempsAuth(): string {
		return this.failedAttempsAuth;
	}

	set FailedAttempsAuth(x: string) {
		this.failedAttempsAuth = x;
	}

	get FailedAttempsEnroll(): string {
		return this.failedAttempsEnroll;
	}

	set FailedAttempsEnroll(x: string) {
		this.failedAttempsEnroll = x;
	}

	get IdentityFirstName(): string {
		return this.identityFirstName;
	}

	set IdentityFirstName(x: string) {
		this.identityFirstName = x;
	}

	get IdentityLastName(): string {
		return this.identityLastName;
	}

	set IdentityLastName(x: string) {
		this.identityLastName = x;
	}

	constructor() {}

	public static fromBackBuild(x: IDataProccess): DataProccess {
		const data = new DataProccess();

		if (x) {
			data.BusinessProcess = x.businessProcess;
			data.Channel = x.channel;
			data.ClientId = x.clientId;
			data.Company = x.company;
			data.DocumentNumber = x.documentNumber;
			data.DocumentType = x.documentType;
			data.OperationType = x.operationType;
			data.TransactionId = x.transactionId;
			data.ReniecId = x.reniecId;

			if (x.signalr) {
				data.SignalR = { ...x.signalr };
			}
			if (x.response) {
				data.Uri = x.response?.uri;
			}

			data.FailedAttempsAuth = x.failedAttempsAuth;
			data.FailedAttempsEnroll = x.failedAttempsEnroll;

			if (x.identityFirstName && x.identityLastName) {
				data.IdentityFirstName = x.identityFirstName;
				data.IdentityLastName = x.identityLastName;
			}
		}
		return data;
	}
}
