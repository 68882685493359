
import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { SpaSsoEndpoint } from 'src/app/views/spasso/endpoints/spasso/spasso.endpoints';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LocalStateService } from './local-state.service';
import { ISignalR } from '@commons/interfaces/signalr.interface';
import { take } from 'rxjs/operators';
import { DataProccess } from '@commons/models/data-proccess.model';
import { CONSTANTS } from '@commons/constants/constants';

@Injectable({
	providedIn: 'root',
})
export class SignalRService {
	public hubConnection: HubConnection;
	public successConnection = new Subject<boolean>();
	public $successConnection: Observable<boolean> = this.successConnection.asObservable();
	public progressMobile: Subject<boolean> = new Subject<boolean>();
	public $progressMobile = this.progressMobile.asObservable();
	public isLoading = new Subject<boolean>();
	public $isLoading = this.isLoading.asObservable();

	public uri: string;
	public transactionId: string;
	public signalR = false;

	public readonly _connectionSignalR: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);
	public $connectionSignalR: Observable<boolean> = this._connectionSignalR.asObservable();

	constructor(private endpoint: SpaSsoEndpoint, private state: LocalStateService) {}

	public async init(): Promise<void> {
		const signalr: ISignalR = await this.connectionSignalService();
		await this.createConnection(signalr.uri, signalr.accessToken);
		// return this.startConnection();
	}

	public async createConnection(url: string, accessToken: string): Promise<void> {
		try {
			this.hubConnection = new HubConnectionBuilder()
				.withUrl(url, { accessTokenFactory: () => accessToken })
				.withAutomaticReconnect()
				.configureLogging(signalR.LogLevel.Information)
				.build();

			await this.startConnection();
		} catch (error) {
			this.isLoadingConnectionSignalR(false);
		}

		// this.hubConnection.onclose((err) => {
		// 	console.log('SignalR hub connection closed', err);
		// 	this.stopHubAndunSubscribeToServerEvents();
		// });
	}

	public restartConnection(err: Error): void {
		// verify internet connection
		const interval = setInterval(() => {
			if (window.navigator.onLine) {
				this.startConnection().then();
				clearInterval(interval);
			}
		}, 1000);
	}

	public startConnection(): Promise<void> {
		return new Promise<void>((resolve, reject): void => {
			this.hubConnection
				.start()
				.then((): void => {
					console.log(
						`SignalR connection success! connectionId: ${this.hubConnection.connectionId}`
					);
					this.subscribeToServerEvents();
					this.connectionExist(true);
					this.isLoadingConnectionSignalR(true);
					this.progressMobileFlow(false);
					resolve();
				})
				.catch((err): void => {
					this.connectionExist(false);
					this._connectionSignalR.next(false);
					this.isLoadingConnectionSignalR(false);
					this.progressMobileFlow(false);
					reject(CONSTANTS.error.SIGNALR_ERROR);
				});
		});
	}

	public subscribeToServerEvents(): void {
		this.hubConnection.on('NOTIFICATION_TRAY', async (data): Promise<void> => {
			if (data.title === 'TRANSACTION_PROGRESS_MOBILE') {
				this.progressMobileFlow(true);
			}

			if (data.title === 'TRANSACTION_COMPLETE_MOBILE') {
				await this.callBack();
				this.closeWindow();
			}

			if (data.title === 'TRANSACTION_FINISHED') {
				this.closeWindow();
			}
		});
	}

	public stopHubAndunSubscribeToServerEvents(): void {
		this.hubConnection.off('NOTIFICATION_TRAY');
		this.hubConnection.stop().then(() => console.log('Hub connection stopped'));
	}

	public async connectionSignalR() {
		try {
			this.connectionExist(true);
			this.isLoadingConnectionSignalR(true);
		} catch (error) {
			this.connectionExist(false);
		} finally {
			setTimeout(() => {
				this.isLoadingConnectionSignalR(false);
			}, 1000);
		}
	}

	public connectionSignalService(): Promise<ISignalR> {
		return new Promise<ISignalR>((resolve, reject) => {
			this.endpoint.getConnectionSignalR().subscribe(
				(res: ISignalR) => {
					resolve(res);
				},
				(err) => {
					reject(err);
				}
			);
		});
	}

	public connectionExist(newValue: boolean) {
		this.successConnection.next(newValue);
	}

	public isLoadingConnectionSignalR(newValue: boolean) {
		this.isLoading.next(newValue);
	}

	public progressMobileFlow(newValue: boolean) {
		this.progressMobile.next(newValue);
	}

	public getDataOperation(): Promise<any> {
		return new Promise<any>((resolve, reject): any => {
			this.state.state<DataProccess>('data-client')?.subscribe((res) => {
				resolve(res);
			});
		});
	}

	public async callBack(): Promise<void> {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const { Uri, TransactionId, SignalR } = await this.getDataOperation();
		if (SignalR) {
			this.signalRCallBack(TransactionId);
		} else {
			this.iFrameCallBack(TransactionId, Uri);
		}
	}

	public signalRCallBack(transactionId: string): void {
		this.endpoint
			.sendMessage(transactionId)
			.toPromise()
			.then((): void => {
				this.closeWindow();
			})
			.catch((err): void => {
				this.closeWindow();
			})
			.finally((): void => {
				this.closeWindow();
			});
	}

	public iFrameCallBack(transactionId: string, uri: string): void {
		const wn = document.getElementsByTagName('iframe').item(0);
		const w = wn?.contentWindow;
		w.postMessage(`${transactionId}`, uri);

		this.closeWindow();
	}

	closeWindow(): void {
		window.close();
		window.open('', '_self').window.close();
	}
}
