import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, enableProdMode, NgModule } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SignalRService } from '@commons/services/signalr.service';
import { environment } from '@env/environment';
import { AppComponent } from './app.component';
import { Modules } from './config';
import { Providers } from './config/providers.config';
import axios from 'axios';
import { SpaSsoEndpoint } from './views/spasso/endpoints/spasso/spasso.endpoints';

export function initializeTransaction(route: ActivatedRoute, spaSsoEndpoint: SpaSsoEndpoint) {
	return (): void => {
		route.queryParams.subscribe((params: Params): void => {
			const { transaction, token } = params;
			if (transaction) {
				sessionStorage.setItem('transaction', transaction);
				spaSsoEndpoint.getToken(transaction).subscribe((token): void => {
					sessionStorage.setItem('identity', token.long_token);
				});
			}
			if (token) sessionStorage.setItem('identity', token);
		});
	};
}

export function initializeEnv() {
	// eslint-disable-next-line no-console
	return async () => {
		if (environment.production) {
			const url = `${window.location.protocol}//${window.location.hostname}/config/config-wp.json`;
			const response = await axios.get(`${url}`);
			const config = await response.data;
			Object.assign(environment, config);
		}
	};
}

@NgModule({
	declarations: [AppComponent],
	imports: [...Modules],
	providers: [
		...Providers,
		SignalRService,
		{
			provide: APP_INITIALIZER,
			useFactory: initializeTransaction,
			deps: [ActivatedRoute, SpaSsoEndpoint],
			multi: true,
		},

		{
			provide: APP_INITIALIZER,
			useFactory: initializeEnv,
			deps: [],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	exports: [],
})
export class AppModule {}
