import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CanActivateViaAuthGuard } from '@commons/guards/auth.guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./views/home/home.module').then((m) => m.HomeModule),
		canActivate: [CanActivateViaAuthGuard],
	},
	{
		path: 'auth',
		loadChildren: () => import('./views/spasso/spasso.module').then((m) => m.SpaSsoModule),
	},
	{
		path: 'enrollment-async',
		loadChildren: () =>
			import('./views/enrollment-async/enrollment-async-host.module').then(
				(m) => m.EnrollmentAsyncHostModule
			),
	},
	// {
	// 	path: 'enrollment-express',
	// 	loadChildren: () =>
	// 		import('./views/enrollment-async/enrollment-async-host.module').then(
	// 			(m) => m.EnrollmentAsyncHostModule
	// 		),
	// },
	// {
	// 	path: 'authentication',
	// 	loadChildren: () =>
	// 		import('./views/enrollment-async/enrollment-async-host.module').then(
	// 			(m) => m.EnrollmentAsyncHostModule
	// 		),
	// },
	{ path: '**', redirectTo: 'auth' },
];

const config: ExtraOptions = {
	useHash: true,
	scrollPositionRestoration: 'enabled',
	preloadingStrategy: PreloadAllModules,
	relativeLinkResolution: 'legacy',
};

@NgModule({
	imports: [RouterModule.forRoot(routes, config)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
