import { ModuleWithProviders, NgModule } from '@angular/core';
import { WINDOW_PROVIDERS } from './new-relic.service';

@NgModule({
	providers: [WINDOW_PROVIDERS],
})
export class NewRelicModule {
	static forRoot(): ModuleWithProviders<any> {
		return {
			ngModule: NewRelicModule,
			providers: [WINDOW_PROVIDERS],
		};
	}
}
