import { HttpParams, HttpHeaders } from '@angular/common/http';

/**
 * Clase que representa un objeto que se envía al servicio de Angular Http
 * para setear su configuración
 */
export class RequestConfig {
	url: string;
	body?: any;
	params?: HttpParams | { [param: string]: string | Array<string> };
	headers?: HttpHeaders | { [header: string]: string | Array<string> };
	responseType?: 'json';
	observe: 'response';
	responseKey: string;
	onlyHttpStatus: boolean;

	constructor() {
		this.observe = 'response';
	}

	public set(key: string, value: any) {
		this[key] = value;
		return this;
	}
}
