import { NgModule } from '@angular/core';
import { CanActivateViaAuthGuard } from './auth.guard';
import { AuthenticateGuard } from './authentication.guard';
import { EnrollmentExpressGuard } from './enrollment-express.guard';
import { EnrollmentGuard } from './enrollment.guard';

@NgModule({
	providers: [CanActivateViaAuthGuard, AuthenticateGuard, EnrollmentGuard, EnrollmentExpressGuard],
})
export class GuardModule {}
