import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GuardModule } from '@commons/guards/guard.module';
import { NewRelicModule } from '@commons/services/new-relic';
import { AppRoutingModule } from '../app-routing.module';

export const Modules = [
	BrowserModule,
	HttpClientModule,
	AppRoutingModule,
	GuardModule,
	BrowserAnimationsModule,
	NewRelicModule,
];
