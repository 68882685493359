import { ErrorHandler, Injectable } from '@angular/core';
import { MessageBusService } from '@assi/kingslanding-commons-light';
import { environment } from '@env/environment';

@Injectable()
export class MonitoringErrorHandler extends ErrorHandler {
	constructor(private messageBus: MessageBusService) {
		super();
	}

	public handleError(error: Error): void {
		this.messageBus.publishTopicInChannel('biometria', 'newrelic.notice-error', error);
		if (!environment.production) {
			super.handleError(error);
		}
	}
}
