import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestConfig } from '@commons/helpers/request.config';
import { HttpIbk } from '@commons/services/http-ibk.service';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { DataProccess } from '@commons/models/data-proccess.model';
import { Utility } from '@commons/helpers/utility';
import { ISignalR } from '@commons/interfaces/signalr.interface';
import { LocalStateService } from '@commons/services/local-state.service';
import { Token } from '../../spasso.container';

/**
 * Representa los endpoints del API que permite obtener la informacion del proceso
 */

@Injectable({ providedIn: 'root' })
export class SpaSsoEndpoint {
	public constructor(
		private http: HttpIbk,
		private utility: Utility,
		private localStateService: LocalStateService
	) {}

	public validateAccess(): Observable<DataProccess> {
		const type: 'MOBILE' | 'WEB_APP' = this.utility.isMobile() ? 'MOBILE' : 'WEB_APP';

		const config: RequestConfig = new RequestConfig();
		config.headers = new HttpHeaders().set('X-Application-Type', type);

		config.url = `${environment.urlWP}/businessProcess`;
		return this.http.get(config, DataProccess.fromBackBuild);
	}

	public getConnectionSignalR(): Observable<ISignalR> {
		const headers = new Headers({
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'Content-Type': 'application/json',
			// eslint-disable-next-line @typescript-eslint/naming-convention
			Authorization: `Bearer ${sessionStorage.getItem('identity')}`,
		});
		const config: RequestConfig = new RequestConfig();
		// eslint-disable-next-line @typescript-eslint/naming-convention
		config.headers = new HttpHeaders().set(
			'Authorization',
			`Bearer ${sessionStorage.getItem('identity')}`
		);
		config.url = `${environment.urlWP}/signalr/negotiate`;
		return this.http.post(config);
	}

	public stopFlow(): Observable<object> {
		const config: RequestConfig = new RequestConfig();
		config.headers = new HttpHeaders();

		config.url = `${environment.urlWP}/businessProcess/completed/notification`;
		return this.http.post(config);
	}

	public sendMessage(transactionId: string): Observable<void> {
		const config: RequestConfig = new RequestConfig();
		config.headers = new HttpHeaders();

		config.body = { title: 'TRANSACTION_FINISHED', message: transactionId };

		config.url = `${environment.urlWP}/signalr/post-message`;
		return this.http.post(config);
	}

	public async sendMessageByXHR(): Promise<void> {
		const token: string = sessionStorage.getItem('identity');

		var url = `${environment.urlWP}/signalr/post-message`;

		var xhr = new XMLHttpRequest();
		xhr.open('POST', url, false);

		xhr.setRequestHeader('Connection', 'keep-alive');
		xhr.setRequestHeader('Keep-Alive', 'timeout=5, max=100');
		// xhr.setRequestHeader(
		//   'Ocp-Apim-Subscription-Key',
		//   '3b8700ab20814ee58e07ffc89e16c86d'
		// );
		xhr.setRequestHeader('Authorization', `Bearer ${token}`);
		xhr.setRequestHeader('Content-Type', 'application/json');

		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4) {
				console.log(xhr.status);
				console.log(xhr.responseText);
			}
		};

		xhr.send(`{
      "title": "TRANSACTION_FINISHED",
      "message": ""
    }`);
	}

	public async sendMessageByFetch(): Promise<void> {
		const token: string = sessionStorage.getItem('identity');

		await fetch(`${environment.urlWP}/signalr/post-message`, {
			keepalive: true,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
				// 'Ocp-Apim-Subscription-Key': '3b8700ab20814ee58e07ffc89e16c86d',
			},
			body: JSON.stringify({ title: 'TRANSACTION_FINISHED', message: '' }),
		});
	}

	public getToken(transaction: string): Observable<Token> {
		const config: RequestConfig = new RequestConfig();
		config.headers = new HttpHeaders();

		config.url = `${environment.urlWP}/shortUrl/${transaction}`;
		return this.http.get(config);
	}
}
