/* eslint-disable @typescript-eslint/naming-convention */
export const CONSTANTS = {
	attempts: {
		MAX_COUNT: 3,
	},

	error: {
		DEVICES_ERROR: 'DEVICES_ERROR',
		ATTEMPTS_ERROR: 'ATTEMPTS_ERROR',
		SIGNALR_ERROR: 'SIGNALR_ERROR',
	},

	transaction: {
		COUNT: 33,
	},
};
