import { Injectable } from '@angular/core';

/**
 * Servicio que expone métodos utilitarios de la sesión.
 *
 * @description Servicio Utility
 */
@Injectable({ providedIn: 'root' })
export class Utility {
	public constructor() {}

	public isMobile(): boolean {
		if (
			navigator.userAgent.match(/Android/i) ||
			navigator.userAgent.match(/webOS/i) ||
			navigator.userAgent.match(/iPhone/i) ||
			navigator.userAgent.match(/iPad/i) ||
			navigator.userAgent.match(/iPod/i) ||
			navigator.userAgent.match(/BlackBerry/i) ||
			navigator.userAgent.match(/Windows Phone/i)
		) {
			return true;
		}
		return false;
	}
}
