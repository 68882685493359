import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utility } from '@commons/helpers/utility';
import * as Bowser from 'bowser';
import { SpaSsoEndpoint } from './views/spasso/endpoints/spasso/spasso.endpoints';

const FIREFOX = 'Firefox';

@Component({
	selector: 'bio-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	public mobile = false;

	public constructor(
		public router: Router,
		private utility: Utility,
		private endpoint: SpaSsoEndpoint,
		private readonly elementRef: ElementRef
	) {
		this.mobile = utility.isMobile();
	}

	public async ngOnInit() {
		const bowser = Bowser.detect(window.navigator.userAgent);
		const type: string = this.mobile ? 'pagehide' : 'beforeunload';
		window.addEventListener(type, () => {
			this.sendMessage(bowser);
		});
		// if (this.mobile) {
		//
		// } else {
		// 	window.addEventListener('beforeunload', (event) => {
		// 		this.sendMessage(bowser);
		// 	});
		// }
	}

	sendMessage({ name }) {
		name === FIREFOX && !this.mobile
			? this.endpoint.sendMessageByXHR()
			: this.endpoint.sendMessageByFetch();
	}

	afterViewInit() {
		const el = this.elementRef.nativeElement.querySelector('facephi-selphid');
		el.addEventListener('scanSuccess', (ev: any) => {
			// Since UI component uses custom events, data is placed in `detail` property
		});
	}
}
