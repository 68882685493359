import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStateService } from '@commons/services/local-state.service';

/**
 *
 * VERIFICA SI TIENES ACCESO AL FLUJO DE ENROLAMIENTO
 */
@Injectable()
export class AuthenticateGuard implements CanActivate {
	constructor(private readonly stateService: LocalStateService, private readonly router: Router) {}

	canActivate(): boolean {
		return this.checkAuthenticated();
	}

	checkAuthenticated(): boolean {
		const access = this.stateService.get('operationType');
		if (access === 'authentication') {
			return true;
		} else {
			this.router.navigate(['auth']);
			return false;
		}
	}
}
