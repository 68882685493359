import { Inject, Injectable } from '@angular/core';
import { MessageBusService } from '@assi/kingslanding-commons-light';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomWindow } from '@commons/services/new-relic';
import { WINDOW } from '@commons/services/new-relic';

@Injectable({
	providedIn: 'root',
})
export class NewRelicBusService {
	private unsubscribe: Subject<void> = new Subject();

	constructor(private messageBus: MessageBusService, @Inject(WINDOW) private window: CustomWindow) {
		this.listenTopic();
	}

	/**
   channel: Nombre del canal obtenido por el servicio /bussinesProcess
   applicationId: Nombre de la aplicación que registro el evento
   ambient: Nombre del ambiente(DEV, UAT, PRD)
   company: Nombre de la compañía obtenido por el servicio /bussinesProcess
   operation: Nombre de la operación a realizar(ENROLAMIENTO, AUTENTICACIÓN)
   userMessage: Mensaje del error obtenida desde backend
   textInfo: Identificador del error.
   *
   * @private
   * @memberof NewRelicBusService
   */
	private listenTopic(): void {
		this.messageBus
			.listenTopicInChannel('biometria', 'newrelic.*')
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((msg) => {
				switch (msg.label) {
					case 'pageaction':
						this.window.newrelic?.addPageAction(msg.data?.msg as string, {
							channel: msg.data?.channel,
							applicationId: 'BFA_WP',
							ambient: environment.ambient,
							company: msg.data?.company,
							operation: msg.data?.operation,
						});
						break;
					case 'notice-error':
						this.window.newrelic?.noticeError(msg.data?.error as Error, {
							applicationId: 'BFA_WP',
							ambient: environment.ambient,
							userMessage: msg.data?.error?.error?.userMessage,
							textInfo: msg.data?.textInfo,
						});
						break;
					default:
						console.warn('No existe tópico para esta opción');
						break;
				}
			});
	}
}
