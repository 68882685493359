import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestConfig } from '@commons/helpers/request.config';

@Injectable({ providedIn: 'root' })
export class HttpIbk {
	constructor(public http: HttpClient) {}

	/**
	 * Wrapper del método GET de HttpClient de Angular. Internamente, si se suministra, convierte la
	 * respuesta en un arreglo de instancias basasa en la clase indicada y ejecuta el callback
	 * dependiendo si la petición fue exitosa o errónea. Con un objeto de configuración se setean los
	 * valores necesarios para realizar la solicitud. Se deben suministrar callbacks por cada estado
	 * Http que se espera recibir.
	 * @param config Objeto que contiene la configuración necesaria para realizar la petición HTTP
	 * @param builder Método constructor para el mapeo de la respuesta del backend en instancias de
	 *   modelos del sistema. Debe manejar la lógica de creación del objeto dado y retornarlo.
	 */
	public get<T>(config: RequestConfig, builder?: (x) => any): Observable<T> {
		return this.http.get<T>(config.url, config).pipe(
			map((response: HttpResponse<T>) => {
				if (response.body && builder) {
					let body = response.body;
					let respuesta;
					if (config.responseKey) {
						body = response.body[config.responseKey];
					}

					if (body instanceof Array) {
						respuesta = body.map((obj) => builder(obj));
					} else {
						respuesta = builder(body);
					}
					return respuesta;
				} else {
					return response.body;
				}
			})
		);
	}

	/**
	 * Wrapper del método POST de HttpClient de Angular. Internamente, si se suministra, convierte la
	 * respuesta en una instancia basasa en la clase indicada y la retorna. Con un objeto de
	 * configuración se setean los valores necesarios para realizar la solicitud.
	 * @param config Objeto que contiene la configuración necesaria para realizar la petición HTTP
	 * @param builder Método constructor para el mapeo de la respuesta del backend en instancias de
	 *   modelos del sistema. Debe manejar la lógica de creación del objeto dado y retornarlo.
	 */
	public post<T>(config: RequestConfig, builder?: (x: T) => any): Observable<T> {
		const requestHeaders = new HttpHeaders().set('Content-Type', 'application/json');
		// eslint-disable-next-line max-len
		return this.http
			.post<T>(config.url, config.body, {
				headers: config.headers ? config.headers : requestHeaders,
				observe: config.observe,
			})
			.pipe(
				map((response) => {
					let respuesta: T = response.body;
					if (respuesta && builder) {
						respuesta = builder(respuesta);
					}
					return respuesta;
				})
			);
	}

	/**
	 * Wrapper del método PUT de HttpClient de Angular. Internamente, si se suministra, convierte la
	 * respuesta en una instancia basasa en la clase indicada y la retorna Con un objeto de
	 * configuración se setean los valores necesarios para realizar la solicitud.
	 * @param config Objeto que contiene la configuración necesaria para realizar la petición HTTP
	 * @param builder Método constructor para el mapeo de la respuesta del backend en instancias de
	 *   modelos del sistema. Debe manejar la lógica de creación del objeto dado y retornarlo.
	 */
	public put<T>(config: RequestConfig, builder?: (x: T) => any): Observable<T> {
		const requestHeaders = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http
			.put<T>(config.url, config.body, { headers: requestHeaders, observe: 'response' })
			.pipe(
				map((response) => {
					let respuesta: T = response.body;

					if (respuesta && builder) {
						respuesta = builder(respuesta);
					}

					return respuesta;
				})
			);
	}

	public patch<T>(config: RequestConfig, builder?: (x: T) => any): Observable<T> {
		const requestHeaders = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http
			.patch<T>(config.url, config.body, { headers: requestHeaders, observe: 'response' })
			.pipe(
				map((response) => {
					let respuesta: T = response.body;
					if (respuesta && builder) {
						respuesta = builder(respuesta);
					}

					return respuesta;
				})
			);
	}

	public getFile(config: RequestConfig): Observable<Blob> {
		return this.http
			.get(config.url, { responseType: 'blob', headers: config.headers, params: config.params })
			.pipe(
				map((response) => {
					return response;
				})
			);
	}

	/**
	 * Wrapper del método DELETE de HttpClient de Angular.
	 * Con un objeto de configuración se setean los valores necesarios para realizar la solicitud.
	 * @param config Objeto que contiene la configuración necesaria para realizar la petición HTTP
	 */
	public delete<T>(config: RequestConfig): Observable<T | number> {
		return this.http.delete<T>(config.url, { headers: config.headers, observe: 'response' }).pipe(
			map((response: HttpResponse<T>) => {
				if (config.onlyHttpStatus) {
					return response.status;
				}
				return response.body;
			})
		);
	}
}
