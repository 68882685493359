import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStateService } from '@commons/services/local-state.service';

/**
 *
 * VERIFICA SI ESTA AUTENTICADO PARA NAVEGAR EN MODULOS
 * DISTINTOS AL LOGIN
 */
@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {
	constructor(private readonly stateService: LocalStateService, private readonly router: Router) {}

	canActivate(): boolean {
		return this.checkAuthenticated();
	}

	checkAuthenticated(): boolean {
		const access = this.stateService.get('access');
		if (access) {
			return true;
		} else {
			this.router.navigate(['/auth']);
			return false;
		}
	}
}
