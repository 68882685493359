import { Injectable, Inject, Optional } from '@angular/core';
import { IStateConfig, StateModelManager, STATE_CONFIG } from '@assi/kingslanding-commons-light';

@Injectable({
	providedIn: 'root',
})
export class LocalStateService extends StateModelManager {
	// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
	public constructor(@Inject(STATE_CONFIG) @Optional() config: IStateConfig) {
		super();
	}
}
