import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpResponse,
	HttpHandler,
	HttpRequest,
	HttpErrorResponse,
	HttpProgressEvent,
	HttpSentEvent,
	HttpHeaderResponse,
	HttpUserEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { MessageBusService } from '@assi/kingslanding-commons-light';

@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor {
	public constructor(private messageBus: MessageBusService) {}

	// eslint-disable-next-line max-len, @typescript-eslint/no-explicit-any
	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<
		| HttpSentEvent
		| HttpHeaderResponse
		| HttpProgressEvent
		| HttpResponse<any>
		| HttpUserEvent<any>
		| any
	> {
		return next.handle(this.addTokenToRequest(request)).pipe(
			timeout(180000),
			catchError((error) => this.interceptError(error, request, next))
		);
	}

	/**
	 * Metodo que  se ejecuta en cada peticion al back
	 * verifica si es necesario colocaler el headers de Autorizacion Bearer ó Basic
	 * @param request peticion al backend
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private addTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
		const headers = { Authorization: '' };
		if (request.headers) {
			if (!request.headers.has('noauth')) {
				const token: string = sessionStorage.getItem('identity');
				headers.Authorization = `Bearer ${token}`;
			}
		}

		return request.clone({ setHeaders: headers });
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private interceptError(error: any, request: HttpRequest<any>, next: HttpHandler) {
		if (error.name === 'TimeoutError') {
			return throwError(error);
		}

		if (error instanceof HttpErrorResponse) {
			switch (error.status) {
				case 401:
					this.messageBus.publishTopicInChannel('bio-admin-web', 'modals.exception-auth', {
						data: error,
						redirecTo: 'STAY',
					});
			}
		}
		return throwError(error);
	}
}
